import React from "react"
import styled from "styled-components"
import sprite from "./sprite.png"
import spriteModern from "./sprite_modern.png"
import FormGroup from "mill/components/FormGroup"
import { Error, HelpText } from "mill/utils/sharedStyles"
import { string } from "prop-types"
import Label from "mill/components/Label"

export const CheckBox = styled.input`
  height: 24px;
  width: 24px;
  max-width: 24px;
  border: none;

  &:before {
    height: 24px;
    width: 24px;
    content: "";
    display: inline-block;
    background-color: #fff;
    background-image: url(${props => (props.modern ? spriteModern : sprite)});
    background-size: 24px 96px;
    background-position: 0 0;
  }

  &:hover {
    cursor: pointer;

    &:before {
      background-position: 0 -24px;
    }
  }

  &:checked {
    &:before {
      background-position: 0 ${props => (props.selector ? `-72px` : `-48px`)};
    }
  }

  ${props =>
    props.solo &&
    `
    display: inline-block;
  `};
  ${props => props.marginRight && `margin-right: 1rem;`};
`

export const FormikCheckbox = ({
  field,
  form: { touched, errors, values },
  helpText,
  inline,
  withFieldset,
  label,
  labelStyle,
  description,
  checked,
  ...props
}) => {
  const fieldHasErrors = touched[field.name] && errors[field.name]
  const contents = () => (
    <div style={inline && { display: "flex" }}>
      <CheckBox
        type={"checkbox"}
        id={field?.id || field.name}
        name={field.name}
        onChange={field.onChange}
        checked={values[field.name]}
        value={values[field.name]}
        hasError={fieldHasErrors}
        {...props}
      />
      <div>
        {label && (
          <Label
            hasError={fieldHasErrors}
            labelStyle={labelStyle}
            title={label}
            forInput={field?.id || field.name}>
            {description}
          </Label>
        )}
        {fieldHasErrors && <Error>{errors[field.name]}</Error>}
        {helpText && <HelpText>{helpText}</HelpText>}
      </div>
    </div>
  )

  return withFieldset ? (
    <FormGroup inline={inline} padding={2} marginTop={props.marginTop || 1}>
      {contents()}
    </FormGroup>
  ) : (
    <div>{contents()}</div>
  )
}

const Checkbox = props => {
  const handleClick = e => {
    const checked = e.target.checked
    if (checked) {
      props.onCheck()
    } else {
      props.onUnCheck()
    }
  }

  const { errorStyle, labelStyle, helpText } = props
  const error = props.meta ? props.meta.error : null
  const touched = props.meta ? props.meta.touched : null

  const renderContextualHelp = () => {
    return touched && error ? (
      <Error errorStyle={errorStyle}>{error}</Error>
    ) : helpText ? (
      <HelpText>{helpText}</HelpText>
    ) : null
  }

  const isChecked = () => {
    return props.checked
      ? true
      : props.input && props.input.value === true
      ? true
      : false
  }

  const renderAligned = () => {
    return (
      <div style={{ display: "flex" }}>
        <CheckBox
          type="checkbox"
          selector={props.selector}
          checked={isChecked()}
          onChange={props.onCheck && props.onUnCheck && handleClick}
          data-qa={props["data-qa"]}
          id={(props.input && props.input.name) || props.id}
          {...props.input}
          marginRight={props.marginRight}
        />
        <div style={{ flex: "1" }}>
          {props.label && (
            <Label
              hasError={touched && error}
              labelStyle={labelStyle}
              htmlFor={props.id}
              title={props.label}>
              {props.description}
            </Label>
          )}
          {renderContextualHelp()}
        </div>
      </div>
    )
  }

  const renderStandard = () => {
    return (
      <div>
        {props.label && (
          <Label
            hasError={touched && error}
            labelStyle={labelStyle}
            title={props.label}>
            {props.description}
          </Label>
        )}
        <CheckBox
          type="checkbox"
          checked={isChecked()}
          onChange={props.onCheck && props.onUnCheck && handleClick}
          selector={props.selector}
          data-qa={props["data-qa"]}
          id={props.input && props.input.name}
          {...props.input}
          marginRight={props.marginRight}
        />
        {renderContextualHelp()}
      </div>
    )
  }

  const renderSolo = () => {
    return (
      <CheckBox
        solo
        modern={props.modern}
        id={props.id}
        name={props.name}
        type="checkbox"
        onChange={props.onCheck && props.onUnCheck && handleClick}
        checked={props.checked}
        selector={props.selector}
        data-qa={props["data-qa"]}
      />
    )
  }

  return props.withFieldset ? (
    <FormGroup>
      {props.alignWithContent ? renderAligned() : renderStandard()}
    </FormGroup>
  ) : props.solo ? (
    renderSolo()
  ) : props.alignWithContent ? (
    renderAligned()
  ) : (
    renderStandard()
  )
}

Checkbox.propTypes = {
  name: string
}

export default Checkbox
